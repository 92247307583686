import React from 'react';
// import loadable from '@loadable/component';





function Buy() {

    return (


        <div className="container">
        </div>



    );
}

export default Buy;