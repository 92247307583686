import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../App.scss';

import loopOne from '../assets/video/bgloop-1.mp4'
import loadable from '@loadable/component';

const MainNav = loadable(()=> import("../components/nav/mainnav"));
const FooterNav = loadable(()=> import("../components/nav/footernav"));
const BgVideo = loadable(()=> import("../components/general/videobackground"));
const Count = loadable(()=> import("../components/general/countdown/count"));


const title = "Syv de Blare"

function Home() {
  const [hasCountdown] = useState(false
    );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={hasCountdown ? "App container homeWrapper withCountdown" : "App container homeWrapper"}>
        <BgVideo video={loopOne} />
        <MainNav />
        {hasCountdown ? <Count /> : ''}
        <FooterNav />
      </div>
    </>
  );
}


export default Home;