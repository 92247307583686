import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../App.scss';
import logo from '../assets/logo/SDB-logo.svg';


import loopOne from '../assets/video/bgloop-1.mp4'
import loadable from '@loadable/component';

const MainNav = loadable(() => import("../components/nav/mainnav"));
const FooterNav = loadable(() => import("../components/nav/footernav"));



const title = "Syv de Blare"

function Home() {
    const [isBlue] = useState(true);
    const [isRed] = useState(false);


    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div className="landing blackBg3D">
                <img src={logo} className="App-logo" alt="logo" width="400" height="192" />

                <div className="wrapper">
                    <iframe src="https://www.youtube.com/embed/umvl6E_aTSc?autoplay=1&mute=1" title="YouTube video player" autoPlay muted frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <FooterNav />
            </div>
        </>
    );
}


export default Home;