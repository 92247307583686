import React, { useState } from 'react';
import loadable from '@loadable/component';

const MainNav = loadable(() => import("../components/nav/mainnav"));
const FooterNav = loadable(() => import("../components/nav/footernav"));
const StreamItem = loadable(() => import("../components/stream/streamitem"));

// import MainNav from '../components/mainnav';
// import FooterNav from '../components/footernav';


function Stream() {
    const [isBlue] = useState(false);
    return (
        <div className={isBlue ? "stream streamBlue" : "streamRed stream"}>
            <MainNav />
            <div className="streamContainer">
                <StreamItem />
            </div>
            <FooterNav />
        </div>

    )
}

export default Stream;