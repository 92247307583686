import React from "react";
import loadable from '@loadable/component';

const MainNav = loadable(() => import("../components/nav/mainnav"));
// const FooterNav = loadable(() => import("../components/nav/footernav"));
const WatchVideos = loadable(() => import("../components/watch/watchvideos"));

// import MainNav from '../components/mainnav';
// import FooterNav from '../components/footernav';


function Stream() {
    return (
        <div className="watch">
            <MainNav />
            <div className="videos">
                <WatchVideos />
            </div>
            {/* <FooterNav /> */}
        </div>

    )
}

export default Stream;