import React, { useState } from 'react';
import loadable from '@loadable/component';


const MainNav = loadable(() => import("../components/nav/mainnav"));
const FooterNav = loadable(() => import("../components/nav/footernav"));
const AboutContent = loadable(() => import("../components/about/aboutcontent"));


function About() {
    const [isBlue] = useState(false);
    return (

        <div className={isBlue ? "about aboutBlue" : "about aboutRed"}>
            <MainNav />
            <div className="container">


                <AboutContent />

            </div>
            <FooterNav />
        </div>



    );
}

export default About;