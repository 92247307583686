import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import loadable from '@loadable/component';

// const Home = loadable(()=> import("./pages/home"));
// const About = loadable(()=> import("./pages/about"));
// const Stream = loadable(()=> import("./pages/stream"));
// const Watch = loadable(()=> import("./pages/watch"));
// const Newsletter = loadable(()=> import("./components/newsletterOptIn"));


import Home from './pages/home';
import AltHome from './pages/altHome';
import About from './pages/about';
import Stream from './pages/stream';
import Watch from './pages/watch';
import Buy from './pages/buy';
import Newsletter from './components/general/newsletterOptIn';




// reportWebVitals(console.log);


ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Switch>
        <Route path="/" exact>
          <AltHome />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/stream">
          <Stream />
        </Route>
        <Route path="/watch">
          <Watch />
        </Route>
        <Route path="/buy">
          <Buy />
        </Route>
        <Route path="/Newsletter">
          <Newsletter/>
        </Route>
      </Switch>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
