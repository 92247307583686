import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";


const SignupSchema = Yup.object().shape({
  email: Yup.string().email(" ").required(" ")
});

const SignUpForm = () => {
  const submitForm = async (values, formik) => {
    console.log(values);
    const { email } = values;
    try {
      const payload = {
        email_address: email,
        status: "subscribed"
      };

      await axios.post("/", payload);
      console.log('Contact details added successfully.');
      formik.resetForm();
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={SignupSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form className="emailSignup">
            <div>
              <label text="Email" required={true} htmlFor="email" className="visuallyhidden">email</label>
              <Field
                id="email"
                name="email"
              ></Field>
              <ErrorMessage
                component="div"
                name="email"
              />
            </div>
            <button
              disabled={!formik.isValid || !formik.dirty}
              type="submit"
            >
              Subscribe
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;